/*各分页start*/
a {
  color: #272727;
  text-decoration: none;
}
a:hover {
  color: #498ae7;
  text-decoration: none;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
img {
  vertical-align: middle;
  max-width: 100%;
}
.blank {
  clear: both;
}

#container-con {
  height: auto;
  margin: 0 auto;
  padding: 16px 0;
  margin-top: 18px;
  clear: both;
}

/* .fen-top{ width:100%; min-width:1330px; height:300px; width:100%; background:url(/public/static/img/fen-top1.jpg) no-repeat center center} 
.fen-top1{width:100%; min-width:1330px; height:300px; width:100%; background:url(/public/static/img/fen-top2.jpg) no-repeat center center} 
.fen-top2{ width:100%; min-width:1330px; height:300px; width:100%; background:url(/public/static/img/fen-top4.jpg) no-repeat center center} 
.fen-top3{width:100%; min-width:1330px; height:300px; width:100%; background:url(/public/static/img/fen-top3.jpg) no-repeat center center} 
.fen-top4{width:100%; min-width:1330px; height:300px; width:100%; background:url(/public/static/img/fen-top5.jpg) no-repeat center center} 

 */

.top_lj {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: url(/public/static/img/top_mbg.png) no-repeat 15px center;
  text-indent: 60px;
  clear: both;
  margin-bottom: 25px;
  font-size: 15px;
  clear: both;
}
.top_js {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: url(/public/static/img/top_mbg.png) no-repeat 15px center;
  text-indent: 60px;
  clear: both;
  margin-bottom: 25px;
  font-size: 15px;
  clear: both;
}
.top_lj strong {
  color: #0045a5;
}

.fen-war {
  clear: both;
  height: auto;
  background: #fff;
  padding: 20px 0;
}

/*分页菜单start*/
.fen-menu {
  float: left;
  width: 280px;
  padding-bottom: 5px;
  min-height: 500px;
  margin-left: 10px;
}
.fen-menu ul.top {
  height: 60px;
  line-height: 60px;
  background: url(/public/static/img/fen_menutop.png) no-repeat bottom right;
  text-indent: 30px;
  font-size: 26px;
  color: #3447a3;
}
.fen-menu ul.list li a {
  display: block;
  height: 46px;
  line-height: 46px;
  background: #f2f2f2;
  margin: 1px 0;
  font-size: 16px;
  color: #2c2b2b;
  text-align: center;
}
.fen-menu ul.list li.active a {
  background:#C70003;
  color:#fff;
}
/* .fen-menu ul.list li a:hover{ background:url(/public/static/img/zwgk_bg2.gif) repeat-x;color:#fff; font-weight:bold; } */

.fen-menu .xmt {
  padding: 10px 0;
  text-align: center;
  background: #f2f2f2;
}
.fen-menu .xmt img {
  width: 150px;
}

.fen-menu .search {
  padding: 20px 0 20px 20px;
  text-align: center;
  background: #f2f2f2;
  margin-top: 16px;
  border-top: 5px solid #78a3e3;
}
.fen-menu .search input.inp {
  height: 30px;
  line-height: 30px;
  border: none;
  width: 180px;
  float: left;
  background: url(/public/static/img/scbar_icon.png) no-repeat left center #fff;
  text-indent: 30px;
}
.fen-menu .search input.btn {
  height: 32px;
  line-height: 32px;
  float: left;
  width: 60px;
  text-align: center;
  background: #d8271c;
  color: #fff;
  font-size: 16px;
  border: none;
  letter-spacing: 2px;
  padding: 0;
  cursor: pointer;
}
.fen-menu .search input.inp:focus {
  background: #f60;
  outline: none;
  border: none;
  color: #fff;
  text-indent: 30px;
  transition: all ease 0.5s;
}

/* .fen-menu ul.list li.this a { display:block; height:46px; line-height:46px; background:url(/public/static/img/zwgk_bg2.gif) repeat-x;color:#fff; margin:1px 0; font-size:16px;  text-align:center; font-weight:bold } */
.fen-menu ul.list li.this p a {
  margin: 5px 0 0 0;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
  font-weight: normal;
  border: none;
}

.fen-menu ul.list li.s a {
  display: block;
  padding: 10px 0 10px 20px;
  line-height: 20px;
  border-right: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  font-size: 16px;
  font-weight: bold;
}
.fen-menu ul.list li.s a:hover {
  border-left: 4px solid #d82416;
  border-bottom: 1px solid #ececec;
  border-right: none;
  text-decoration: none;
  color: #d82416;
}
.fen-menu ul.list li.sthis a {
  display: block;
  padding: 10px 0 10px 17px;
  line-height: 24px;
  border-left: 4px solid #d82416;
  border-bottom: 1px solid #ececec;
  font-size: 16px;
  font-weight: bold;
  color: #d82416;
  border-right: none;
}
.list-ej {
  padding: 10px 20px;
  background: #fff;
  border: 8px solid #e3f1f8;
}
/* .list-ej span{ display:block; height:35px; line-height:35px; border-bottom:1px solid #e3f1f8; background:url(/public/static/img/arrow_001.gif) no-repeat left center; text-indent:30px;} */

/*分页内容start*/
.fen-right {
  float: right;
  width: 750px;
}
.fen-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 3px solid #e9e1df;
  margin-bottom: 10px;
  clear: both;
}
.fen-title p {
  width: 421px;
  float: left;
  font-size: 22px;
  font-weight: 400;
  color: #d82416;
  letter-spacing: 1px;
  background: url(/public/static/img/icon_arr.gif) no-repeat left center;
  text-indent: 30px;
}

.WorkPicList {
  width: 100%;
  padding: 10px 0;
  float: left;
}
.WorkPicList li {
  height: 130px;
  width: 910px;
  border-bottom: 1px solid #e6e6e6;
  float: left;
  padding: 16px 0px;
  text-align: left;
  background: #fff;
  transition: all ease 0.5s;
}
.WorkPicList li img {
  width: 190px;
  height: 130px;
  float: left;
}
.WorkPicList li p {
  display: block;
  width: 680px;
  padding: 0 20px;
  float: left;
  margin: 0;
}
.WorkPicList li p b {
  font-size: 17px;
  color: #323232;
  font-weight: normal;
  width: 100%;
  height: 30px;
  line-height: 28px;
  padding: 0 0 5px 0;
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.WorkPicList li p b:hover {
  font-weight: bold;
}
.WorkPicList li p span.zy {
  width: 100%;
  float: left;
  line-height: 21px;
  color: #666;
  font-size: 13px;
  padding: 0 0 8px 0;
  height: 60px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #a0a0a0;
}
.WorkPicList li p span.WorkDate {
  color: #666;
  padding: 0;
  font-size: 16px;
  height: 20px;
}
.WorkPicList li:hover {
  background: #f7f7f7;
}

.WorkPicList1 {
  width: 100%;
  padding: 10px 0;
}
.WorkPicList1 li {
  border-bottom: 1px solid #e6e6e6;
  padding: 16px 0px;
  text-align: left;
  background: #fff;
  transition: all ease 0.5s;
  cursor: pointer;
}
.WorkPicList1 li p {
  display: block;
  padding: 0 0px;
  margin: 0;
}
.WorkPicList1 li p b {
  font-size: 17px;
  color: #323232;
  font-weight: normal;
  width: 100%;
  height: 30px;
  line-height: 28px;
  padding: 0 0 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.WorkPicList1 li p b:hover {
  font-weight: bold;
}
.WorkPicList1 li p span.zy {
  width: 100%;
  line-height: 21px;
  color: #666;
  font-size: 13px;
  padding: 6px 0 12px 0;
  height: 50px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #a0a0a0;
  margin-bottom: 10px;
}
.WorkPicList1 li p span.WorkDate {
  color: #666;
  padding: 0;
  font-size: 16px;
  height: 20px;
}
.WorkPicList1 li:hover {
  background: #f7f7f7;
}

.fen-article-con {
  padding: 10px 30px;
  font-size: 16px;
  color: #333;
}
.fen-article-con ul.t {
  background: url(/public/static/img/fen_line.gif) repeat-x bottom;
  line-height: 35px;
  font-size: 20px;
  text-align: center;
  padding: 10px 30px 25px 30px;
}
.fen-article-con ul.zy {
  height: 30px;
  line-height: 30px;
  color: #999;
  text-align: center;
  font-size: 16px;
}
.fen-article-con ul.zy span {
  padding: 0 10px;
}
.fen-article-con ul.con {
  padding: 20px 0;
  line-height: 35px;
  color: #333;
}
.fen-article-con h2 {
  text-align: center;
  color: #1c57b1;
  font-size: 24px;
  font-weight: bold;
}
.fen-article-con h3 {
  text-align: center;
}
.fen-article-con a {
  color: #0074ce;
}
.fen-article-con a:hover {
  color: #f60;
}

.fen-article-con ul.conYhzc {
  padding: 20px 0;
  line-height: 35px;
  color: #333;
  font-size: 18px;
  font-family: '瀹嬩綋';
  color: #000;
  line-height: 30px;
}
.fen-article-con ul.conYhzc p {
  text-indent: 0em;
  padding: 8px 0;
}
.news_content {
  margin: 30px 0;
  line-height: 32px;
  font-size: 16px;
  text-indent: 2em;
}
