.Engbackdrop-title {
  position: relative;
  height: 50px;
  margin: 60px 0 30px;
}
.Engbackdrop-title > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 50px;
  font-family: 'Microsoft Yahei';
  font-weight: 700;
}
.Engbackdrop-title .title-text {
  letter-spacing: 3px;
  font-size: 24px;
  color: #333;
}
.Engbackdrop-title .title-bg {
  white-space: nowrap;
  color: rgba(186, 128, 93, 1);
  font-size: 52px;
  opacity: 0.15;
}

.keyword-lists {
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.keyword-lists li {
  display: block;
  flex: 0 0 170px;
}
.keyword-lists li a {
  display: block;
  padding: 24px 0 30px;
  border: 1px #dedede solid;
  text-align: center;
  font-size: 16px;
  color: #333;
}
.keyword-lists li a span {
  display: block;
  margin-top: 10px;
}
.keyword-lists li a img {
  width: 56px;
  height: 56px;
}
.keyword-lists li a .keyword-icon-hover,
.keyword-lists li a:hover .keyword-icon {
  display: none;
}
.keyword-lists li a:hover .keyword-icon-hover {
  display: inline-block;
}

.carousel {
  position: relative;
}
.special-service .carousel-inner {
  width: 1120px;
  margin: 0 auto;
}
.special-service .carousel-inner .item {
  overflow: hidden;
}
.special-service .carousel-inner .item > a {
  float: left;
  display: block;
  width: 270px;
  height: 203px;
  margin: 0 5px;
  transition: 0.25s all;
}
.special-service .carousel-inner .item > a img {
  width: 100%;
}
.link-special-service {
  background: #e1e8f1;
  text-align: center;
}
.link-special-service:hover {
  background: #ba805d;
}
.link-special-service span {
  display: inline-block;
  margin-top: 83px;
  padding: 0 20px;
  background: #fff;
  color: #ab7858;
  line-height: 36px;
  border-radius: 18px;
}
.special-service .carousel-control {
  width: 40px;
  height: 40px;
  top: 50%;
  margin-top: -20px;
  background: none;
  border: 2px #ccc solid;
  border-radius: 20px;
  text-shadow: none;
  color: #ccc;
  font-size: 28px;
  line-height: 34px;
}

.linkbar_index {
  overflow: hidden;
  margin: 0 auto;
}
.linkbar_index li {
  float: left;
  width: 33.3%;
}
.linkbar_index li a {
  display: block;
  margin: 20px 30px 50px;
  padding: 20px;
  border: 1px #dedede solid;
  text-align: center;
}
.linkbar_index li img {
  transition-duration: 0.3s;
}
.linkbar_index li a:hover img {
  transform: scale(1.1);
}

.slidebox {
  width: 600px;
  height: 354px;
  overflow: hidden;
  position: relative;
}
.slidebox .carousel-indicators {
  top: 10px;
  bottom: auto;
  right: 10px;
  left: auto;
  margin-left: 0;
  text-align: right;
}
.slidebox .carousel-indicators li {
  background: #fff;
  width: 12px;
  height: 12px;
  margin: 0 8px 0 0;
}
.slidebox .carousel-indicators li.active {
  background: #f09a37;
  border-color: #f09a37;
}
.subjectbox {
  width: 560px;
  height: 354px;
  overflow: hidden;
}
.subject-tabbar {
  margin-bottom: 20px;
}
.subject-tabbar li {
  display: inline-block;
  margin-right: 30px;
  font-size: 24px;
  color: #999;
  cursor: pointer;
}
.subject-tabbar li.active {
  color: #333;
}
.subject-content {
  margin-left: -10px;
  margin-right: -10px;
}
.subject-content a {
  float: left;
  display: block;
  width: 270px;
  background: #f5f5f5;
  transition: all 0.3s;
}
.group-row-2 a {
  height: 140px;
  margin: 0 10px 20px;
}
.group-row-5 a {
  height: 50px;
  margin: 10px 10px 0;
}
.group-row-2 a:hover {
  margin: -5px 10px 25px;
}
.group-row-5 a:hover {
  margin: 5px 10px;
}
.clearleft {
  clear: left;
}
.subject-content a .subject-item-til {
  display: block;
  margin: 40px 25px 0;
  font-size: 18px;
  color: #333;
}
.subject-content a .subject-item-intro {
  display: block;
  margin: 10px 25px 0;
  font-size: 16px;
  color: #666;
}
.subject-content a .subject-item-text {
  display: block;
  margin: 0 25px;
  font-size: 16px;
  color: #333;
  text-align: center;
  line-height: 50px;
}

/* 特殊服务 */
.swiperwrap2 {
  height: 180px;
  display: flex;
  justify-content: space-between;
}
/* .swiperwrap2 .swiper {
  height: 150px;
} */
.items2 a {
  display: block;
}
.items2 img {
  width: 252px;
  /* height: 150px; */
}

.miniswiper {
  width: 500px;
  height: 344px;
  padding: 0;
}
.miniswiper .items2 a {
  display: block;
}
.miniswiper .items2 img {
  width: 100%;
  height: 344px;
}
.miniswiper .swiper-pagination-bullets {
  top: 10px;
  text-align: right;
  bottom: auto;
}
.center-title-1 h2 {
  font-size: 27px;
}
.paragraph {
  text-indent: 30px;
  margin: 0 0 10px;
}
.paragraphImage {
  width: 100%;
  margin: 0 0 10px;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: linear;
}

.servicedetail .call {
  margin: 50px 75px 30px;
}
.weekly img {
  width: 100%;
  margin: 20px 0 0 0;
}
