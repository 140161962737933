.center-title {
  width: 200px;
  /* height: 20px; */
  margin: 0 auto;
}
.center-title h3 {
  display: block;
  font-size: 20px;
}
.lineText {
  width: 200px;
  text-align: center;
  line-height: 0;
  border-bottom: 1px solid #4774b6;
}
.lineText span {
  font-size: 12px;
  padding: 4px 10px;
  background-color: #fff;
}
.listenter {
  justify-content: center;
  margin-top: 40px;
  width: 100%;
}
.listenter li {
  position: relative;
}
.listenter li + li {
  margin-left: 40px;
}
.listenter video {
  width: 100%;
  object-fit: fill;
}
.pcVideo {
  display: block;
  width: 300px !important;
  height: 140px !important;
  background: url(/public/static/img/2.jpg) center / cover no-repeat;
}
.listenter li:nth-of-type(2) a {
  background-image: url(/public/static/img/3.jpg);
}
.listenter li a:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 300px !important;
  height: 140px !important;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
.listenter li:nth-of-type(3) a {
  background-image: url(/public/static/img/4.jpg);
}
.listenter h4 {
  margin-bottom: 0;
  margin-top: 8px;
}
.play {
  width: 30px;
  position: absolute;
  left: 135px;
  top: 55px;
  opacity: 0.8;
}
.list .t {
  border: 1px solid #eee;
  border-top: none;
  line-height: 32px;
  padding: 0 10px;
}
.wx .center {
  padding: 40px 150px 0 100px;
  box-sizing: border-box;
  line-height: 24px;
}
.wx img {
  margin-left: 100px;
}
.form {
  width: 600px;
  margin: 0 auto;
}
.call {
  background: url(/public/static/img/call.png) 0/24px no-repeat;
  padding-left: 40px;
  line-height: 24px;
  margin: 50px 27px 30px;
  font-size: 16px;
}
.ant-form-item-label > label,
.ant-form-item-explain,
.ant-form-item-extra,
.ant-form-item,
.ant-input,
.ant-picker-input > input {
  font-size: 14px;
}

.route {
  padding-left: 60px;
}
.route h2 {
  margin-top: 30px;
}
.route img {
  margin-top: 10px;
  width: 600px;
}
.route video {
  margin-top: 10px;
  width: 700px;
}

.route p {
  width: 700px;
  text-align: left;
  margin: 0 auto 1em;
}