.search {
  display: flex;
  width: 100%;
  height: 48px;
  background: #fff;
  margin-top: 20px;
  border: 1px solid #ccc;
  // box-sizing: border-box;
  padding: 0 !important;
  .item {
    width: 25%;
  }
  div {
    // flex: 1;
    border-right: 1px solid #ccc;
    box-sizing: border-box;
    .ant-input {
      border: none;
      height: 46px;
      border-radius: 0 !important;
    }
    .ant-select {
      width: 100% !important;
      height: 46px;
    }
    .ant-select-selection-item {
      line-height: 46px !important;
    }
    .ant-select-selector {
      height: 46px !important;
      border: none !important;
      border-radius: 0 !important;
    }
  }
  .btn {
    width: 25%;
    color: #fff;
    background-color: #496fbb;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
.activity-list {
  margin: 20px 0 0;
  .itemList {
    padding: 0 40px;
    border: 1px solid #e3e9ee;
    li {
      display: flex;
      padding: 20px 0;
      .img {
        width: 184px;
        flex-shrink: 0;
        position: relative;
        img {
          object-fit: cover;
        }

        .tip {
          position: absolute;
          top: 10px;
          left: 10px;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 5px 10px;
          font-size: 12px;
          color: #fff;
          border-radius: 3px;
          line-height: 14px;
          display: flex;
          align-items: center;
          i {
            display: block;
            width: 12px;
            height: 12px;
            margin: 1px 5px 1px 0;
            background: url('../../../public/static/img/icon-home.png')
              no-repeat;
            background-position: -37px -156px;
          }
        }
      }
      .info {
        width: 100%;
        margin: 0 0 0 20px;
        position: relative;
        .title {
          padding: 0 0 15px;
          font-size: 20px;
          font-weight: 700;
          color: #324057;
        }
        .txt {
          color: #99a9be;
          // height: 120px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          margin-bottom: 50px;
          height: 66px;
        }
        .dl {
          display: flex;
          height: 16px;
          line-height: 16px;
          margin-bottom: 10px;
          color: #99a9be;
          .dt {
            margin: 0 10px 0 0;
            img {
              width: 20px;
              margin: 0 5px 0 0;
            }
          }
        }
        .btn {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 140px;
          padding: 14px 0;
          background-color: #ccd2d8;
          border-radius: 5px;
          color: #fff;
          text-align: center;
          font-size: 14px;
        }
        .toSignUp {
          background: #496fbb;
          cursor: pointer;
        }
      }
    }
  }
}
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.activity-detail {
  .act-head {
    padding: 30px;
    border: 1px solid #e3e9ee;
    display: flex;
    .img {
      flex-shrink: 0;
      position: relative;
      .img_1 {
        min-height: 251px;
        object-fit: cover;
      }
      .tip {
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 5px 10px;
        font-size: 12px;
        color: #fff;
        border-radius: 3px;
        line-height: 14px;
        display: flex;
        align-items: center;
        i {
          display: block;
          width: 12px;
          height: 12px;
          margin: 1px 5px 1px 0;
          background: url('../../../public/static/img/icon-home.png') no-repeat;
          background-position: -37px -156px;
        }
      }
    }
    .intro {
      width: 100%;
      margin: 0 0 0 30px;
      .title {
        line-height: 30px;
        margin: 5px 0 25px;
        font-size: 24px;
        color: #3b4857;
        font-weight: bold;
      }
      .tips {
        margin-bottom: 40px;
        span {
          display: inline-block;
          margin-right: 10px;
          height: 30px;
          line-height: 30px;
          border: 1px solid #a0adf6;
          border-radius: 8px;
          background-color: #eef1fc;
          color: #5771e6;
          padding: 0 15px;
        }
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        .item {
          color: #475469;
          font-size: 14px;
          line-height: 22px;
          padding-bottom: 18px;
          padding-right: 30px;
          box-sizing: border-box;
          display: flex;
          .dt {
            width: 50px;
            text-align: center;
            background: url('../../../public/static/img/icon-news.png')
              no-repeat -46px -21px;
            margin: 0 10px 0 0;
          }
        }
        .w100 {
          width: 100%;
        }
      }
    }
    .btn {
      flex-shrink: 0;
      width: 160px;
      height: 50px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      line-height: 50px;
      background-color: #ff6b5b;
      border-radius: 8px;
    }
    .toSignUp {
      background-color: #496fbb;
    }
  }
  .act-body {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .block-main {
      width: 1200px;
      border: 1px solid #e3e9ee;
      .ant-tabs-nav {
        display: flex;
        height: 50px;
        align-items: center;
        background-color: #fbfdff;
        border-bottom: 1px solid #e3e9ee;
        padding: 0 25px;
        font-weight: bold;
      }
      .ant-tabs-content-holder {
        padding: 0 25px;
      }
      // .my-tab {
      //   display: flex;
      //   height: 50px;
      //   align-items: center;
      //   background-color: #fbfdff;
      //   border-bottom: 1px solid #e3e9ee;
      //   .item {
      //     padding: 0 25px;
      //     font-weight: bold;
      //   }
      // }
    }
    .block-side {
      width: 260px;
      border: 1px solid #e3e9ee;
      .title {
        background-color: #fbfdff;
        border-bottom: 1px solid #e3e9ee;
        font-weight: bold;
        height: 50px;
        line-height: 50px;
        padding: 0 25px;
        margin: 0 0 10px;
      }
      .item {
        display: flex;
        align-items: center;
        padding: 0 25px;
        margin: 0 0 10px;
        .num {
          background-color: #d1c0a5;
          width: 14px;
          height: 14px;
          line-height: 14px;
          color: #fff;
          text-align: center;
          font-size: 12px;
          flex-shrink: 0;
        }
        .txt {
          margin-left: 24px;
          color: #68737d;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
.signUpModel {
  .ant-input {
    margin-top: 10px;
  }
}
