.swiper {
  height: 400px;
  padding: 0;
  img {
    height: 400px;
    object-fit: fill;
  }
  .swiper-button-next, .swiper-button-prev{
    width: 30px;
    padding:10px 0 0;
    &::after{
      font-size: 20px;
      background:rgba(0,0,0,0.3);
      color: #fff;
      line-height:1.5;
    }
  }
}
.more {
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 10px;
    margin-left: 4px;
  }
}
.announcement {
  font-size: 18px;
  height: 77px;
  box-shadow: 0px 0px 18px 0px rgba(120, 137, 184, 0.3);
  padding: 0 40px 0 0;
  margin-bottom: 40px;
  .l {
    // margin: -5px 0 0 0;
    width: 200px;
  }
  .r {
    width: 1000px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .swiper {
      height: 36px;
      margin: 0;
    }
    .item {
      max-width: 850px;
      width: auto;
      line-height: 36px;
      // background: rgba(66, 105, 219, 0.06);
      color: #080808;
      padding: 0 14px;
      margin: 0 20px 0 0;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 1;
      // overflow: hidden;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.content {
  display: flex;
  justify-content: flex-start;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 15px;
    div {
      font-size: 20px;
    }
  }

  // 小镇新闻
  .news {
    width: calc(100% - 328px);
    .list {
      width: 100%;
      .item {
        &:first-child {
          min-width: 47%;
          max-width: 450px;
          height: 318px;
          margin-right: 10px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            // height: 318px;
            width: 100%;
            height: 100%;
          }
          .bot {
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
            height: 44px;
            .bot_title {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        &:nth-child(2),
        &:nth-child(3) {
          margin: 0 0 10px;
        }
        &:nth-child(2),
        &:nth-child(4) {
          margin-right: 10px;
        }
        width: 25%;
        height: 154px;
        border: 1px solid #ccc;
        float: left;
        img {
          height: 100px;

        }
        .bot {
          padding: 0 10px;
          color: #324057;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
  // 便捷入口
  .convenient {
    margin: 0 0 0 20px;
    width: 328px;
    .list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .item {
        width: 106px;
        height: 106px;
        border: 1px solid #f0f1f2;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        box-sizing: border-box;
        .icon {
          width: 36px;
          height: 36px;
          background: url(../../../public/static/img/icon-home.png) no-repeat;
        }

        &:nth-child(1) {
          .icon {
            background-position: 0 -177px;
          }
        }
        &:nth-child(2) {
          .icon {
            background-position: 0 -435px;
          }
        }
        &:nth-child(3) {
          .icon {
            background-position: 0 -474px;
          }
        }
        &:nth-child(4) {
          .icon {
            background-position: 0 -510px;
          }
        }
        &:nth-child(5) {
          .icon {
            background-position: 0 -399px;
          }
        }
        &:nth-child(6) {
          .icon {
            background-position: 0 -103px;
          }
        }
        &:nth-child(7) {
          .icon {
            background-position: 0 -140px;
          }
        }
        &:nth-child(8) {
          .icon {
            background-position: 0 -548px;
          }
        }

        &:nth-child(9) {
          .icon {
            background-position: 0 -362px;
          }
        }
        .text {
          width: 100%;
          font-size: 12px;
          color: #324057;
          text-align: center;
        }
      }
    }
  }
}
// 活动
.activity {
  padding: 30px 0 0;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 15px;
    div {
      font-size: 18px;
    }
  }
  .list {
    display: flex;
    justify-self: flex-start;
    .item {
      width: 184px;
      height: 294px;
      margin: 0 10px;
      border: 1px solid #ccc;
      &:first-child {
        margin: 0 10px 0 0;
      }
      &:last-child {
        margin: 0;
      }
      position: relative;
      .status {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        top: 10px;
        left: 10px;
        font-size: 12px;
        color: #fff;
        padding: 5px 10px;
      }
      .tips {
        position: absolute;
        bottom: 40px;
        padding: 5px 10px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
      }
      .img {
        height: 252px;
      }
      .bottom {
        display: flex;
        justify-content: center;
        height: 40px;
        align-items: center;
        color: #99a9be;
        font-size: 14px;
        .time {
          margin: 0 10px 0 0;
        }
      }
    }
  }
}
