.banner {
  width: 1200px;
}
.news {
  /* background-color: #f4f4f4; */
}
/* .mainnews::before { content: ""; display: inline-block; width: 4px; height: 20px; background-color: #496fbb; margin-right: 10px; position: relative; top: 3px;} */
.sn {
  display: flex;
  height: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 30px;
  background: url(/public/static/img/rect.png) left center / contain no-repeat;
}
.sn a {
  cursor: pointer;
  transition-duration: 0.3s;
}
.mainnews a {
  height: 30px;
  line-height: 30px;
  margin-right: 20px;
  font-size: 22px;
  padding-bottom: 0px;
}
.mainnews a.active,
.mainnews a:hover {
  color: #496fbb;
  border-bottom: 2px solid #496fbb;
}
.mainnews .h3 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}
.mainnews p {
  color: #999;
  line-height: 22px;
}
.newslist {
  margin-left: 20px;
}
.newslist li {
  line-height: 40px;
  border-bottom: 1px dashed #496fbb;
  margin-left: 20px;
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 540px;
}
.newslist li::before {
  position: relative;
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #ccc;
  border-radius: 2px;
  margin-right: 10px;
}
.quick {
  height: 380px;
  background: url(/public/static/img/bg.jpg) center 0 / cover no-repeat;
  position: relative;
}
.quick .center {
  padding-top: 20px;
}
.quick .center h3 {
  color: #495bbb;
}
.tit {
  margin: 0 auto;
  color: #495bbb;
  text-align: center;
  width: 240px;
  position: relative;
}
.tit::before,
.tit::after {
  position: absolute;
  content: "";
  width: 120px;
  height: 1px;
  background-color: #495bbb;
  top: 46px;
  left: -70px;
}
.tit::after {
  left: auto;
  right: -70px;
}
.tit h3 {
  font-weight: normal;
  line-height: 24px;
  font-size: 24px;
  padding-top: 20px;
}
.tit p {
  font-size: 12px;
  margin-top: 3px;
}
.enter {
  color: #fff;
  font-size: 18px;
}
.enter > div {
  flex: 1;
  text-align: center;
  padding-top: 60px;
  cursor: pointer;
}
.enter div div {
  height: 80px;
  width: 80px;
  background: url(/public/static/img/1.png) center 0 no-repeat;
  margin: 0 auto 10px;
}
.enter div:nth-of-type(2) div {
  background-image: url(/public/static/img/2.png);
}
.enter div:nth-of-type(3) div {
  background-image: url(/public/static/img/3.png);
}
.enter div:hover div {
  background-position: 0 -80px;
}
.comp {
  /* height: 650px; */
  margin: 40px auto 10px !important;
  /* display: flex; */
  /* flex-direction: column; */

  /* 分别表示两行的高度 */
  /* grid-template-rows: 100px 200px; */
}
.comp ul {
  display: grid;
  /* grid-gap: 2%; */
  grid-template-columns: repeat(auto-fill, 25%);
  justify-items: center;
  justify-content: space-around;
  grid-row-gap: 30px;
}
.comp li {
  width: 208px;
  margin-bottom: 10px;
  display: block;
}
.comp a {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #eee;
}
.comp .img {
  width: 100%;
  padding: 10px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}
.comp img {
  width: 100%;
  height: 105px;
  object-fit: cover;
  text-align: center;
}
.comp .name {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 5px 10px;
  text-align: center;
}
.dot {
  text-align: center;
  margin-bottom: 40px;
}
.dot span {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 2px solid #495bbb;
  margin: 0 4px;
  border-radius: 50%;
}
.dot span.active {
  background-color: #495bbb;
}
.subt {
  color: #496fbb;
  font-weight: normal;
  margin-bottom: 12px;
  font-size: 24px;
}
.subt + p {
  color: #757575;
  margin-bottom: 30px;
}

.swiperwrap {
  height: 300px;
  /* padding: 20px 30px; */
  /* background-color: #f4f4f4; */
}
.swiper {
  height: 200px;
  padding: 0 15px;
}
.items {
  position: relative;
  cursor: pointer;
}
.items p {
  position: absolute;
  background-color: #fff;
  padding: 10px;
  line-height: 16px;
  bottom: -40px;
  left: 5%;
  width: 90%;
  height: 70px;
  box-shadow: 0 0 10px #d2d2d2;
}
.imgwrap {
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.items img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  transition-duration: 0.3s;
}
.items:hover img {
  transform: scale(1.2);
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 2px;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "<";
  flex: 0 0 28px;
  height: 28px;
  background-color: #fff;
  color: #666;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
  line-height: 28px;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 2px;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: ">";
  display: block;
  flex: 0 0 28px;
  height: 28px;
  background-color: #fff;
  color: #666;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
  line-height: 28px;
}
.swiper-button-next,
.swiper-button-prev {
  outline: none;
}
/* .more {
  outline: none;
  width: 70px;
  height: 24px;
  background-color: transparent;
  border: 1px solid #495bbb;
  color: #495bbb;
  border-radius: 12px;
  cursor: pointer;
  transition-duration: 0.4s;
}
.more:hover {
  background-color: #495bbb;
  color: #fff;
} */

h3 {
  display: inline;
}

.quickList a {
  justify-content: center;
  color: #fff;
}
.quickList:hover a {
  color: #fff;
}
