.show {
  width: 1080px;
  height: 1920px;
  overflow: hidden;
  margin: 0 auto;
  .s_t {
    width: 100%;
    height: 450px;
    .swiper {
      height: 450px;
      img {
        height: 450px;
        // object-fit: cover;
      }
    }
  }
  .s_c {
    width: 100%;
    height: 240px;
    .swiper {
      height: 240px;
      img {
        height: 240px;
        // object-fit: cover;
      }
    }
  }
  .s_b {
    height: 1230px;
    display: flex;
    .l {
      width: 300px;
      height: 1230px;
      background-image: linear-gradient(
        269deg,
        #0346ab 0%,
        #094aad 11%,
        #0f5eda 53%,
        #2779fd 100%
      );
      z-index: 99;
      box-shadow: 0 10px 20px 0 #0043aa;
      .logo {
        width: 200px;
        height: 72px;
        margin: 50px 50px 90px;
      }
      .date {
        text-align: center;
        font-weight: 500;
        font-size: 24px;
        color: #ffffff;
      }
      .time {
        font-weight: Bold;
        font-size: 80px;
        color: #ffffff;
        text-align: center;
        margin: 10px 0 30px;
      }
      .weather {
        display: flex;
        justify-content: center;
        margin: 0 0 60px;
        .icon {
          img {
            width: 93px;
          }
        }
        .info {
          width: 103px;
          text-align: center;
          .city {
            width: 80px;
            margin: 0 auto;
            height: 26px;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 22px;
            font-weight: 500;
            font-size: 18px;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .temperature {
            font-weight: 500;
            font-size: 18px;
            color: #ffffff;
            margin: 10px 0 0;
          }
        }
      }
      .code_list {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        .item {
          margin-bottom: 20px;
          &:nth-child(2n) {
            margin: 0 0 0 20px;
          }
          .img {
            width: 120px;
            height: 120px;
            border: 5px solid rgba(255, 255, 255, 0.5);
            box-sizing: border-box;
            img {
              width: 110px;
              height: 110px;
              background: #fff;
            }
          }
          span {
            display: block;
            font-weight: 500;
            font-size: 18px;
            color: #ffffff;
            text-align: center;
            margin: 15px 0 0;
          }
        }
      }

      .phone {
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
        margin: 0 20px 30px;
        // text-align: center;
        line-height: 20px;
      }
    }
    .r {
      width: 780px;
      // height: 1100px;
      background-image: linear-gradient(-45deg, #0051dd 0%, #29b8ff 100%);
      position: relative;
      .r_bg {
        width: 780px;
        position: absolute;
        bottom: 0;
      }
      .information {
        width: 720px;
        height: 60px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 37px;
        display: flex;
        margin: 58px 30px 90px;
        .information_l {
          width: 150px;
          height: 60px;
          background: #da4141;
          border-radius: 100px 0 0 100px;
          font-weight: 500;
          font-size: 24px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
        }
        .information_r {
          // white-space: nowrap; /* 文字不折行 */
          height: 60px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          font-size: 18px;
          color: #002054;
          line-height: 60px;
          padding: 0 15px;
          // overflow: hidden;
        }
      }
      .list {
        display: grid;
        grid-template-columns: repeat(3, 160px);
        grid-row-gap: 90px;
        grid-column-gap: 90px;
        margin: 0 60px;
        position: absolute;
        .item {
          img {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            object-fit: cover;
            border: 4px solid #fff;
            box-shadow: 0 4px 10px 0 rgba(0, 32, 64, 0.3);
          }
          .title {
            width: 160px;
            height: 44px;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 24px;
            color: #ffffff;
            margin: 20px 0 0;
          }
        }
      }
    }
  }
}
.imgMask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  .close {
    color: #000000;
    position: absolute;
    right: 30px;
    bottom: 30px;
    z-index: 100;
    font-size: 30px;
  }
  .swiper {
    width: 100vw;
    height: 100vh;
    img {
      width: 100%;
      height: 100vh;
    }
  }
  .cxjt {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
