.recommended {
  .tabs {
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    margin: 20px auto 1px;
    a {
      width: 116px;
      line-height: 45px;
      text-align: center;
    }
    .active {
      color: #fff;
      background: #496fbb;
    }
  }
  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 32%);
    // grid-row-gap: 30px;
    grid-column-gap: 2%;
    margin: 20px 0 0;
    // grid-gap: 2%;
    .item {
      margin: 0 0 30px;
      &:nth-child(3n) {
        margin: 0;
      }
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
      .b {
        display: flex;
        align-items: center;
        margin: 10px 0 0;
        .l {
          width: 50px;
          font-size: 14px;
          line-height: 15px;
          flex-shrink: 0;
          border-right: 1px solid #ccc;
          div {
            font-size: 16px !important;
            font-weight: bold;
          }
        }
        .r {
          padding: 0 0 0 8px;
          .title {
            font-weight: bold;
            font-size: 16px;
          }
          .detail {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.detailList {
  .title {
    text-align: center;
    color: #1c57b1;
    font-size: 24px;
    font-weight: bold;
  }
  .top_lj {
    margin: 25px 0;
  }
  div {
    p {
      margin-top: 10px;
      text-indent: 2em;
      width: 100%;
      font-size: 17px;
    }
    img {
      width: 100%;
    }
  }
}
.detailList_link {
  text-align: center;
  width: 100px;
  height: 40px;
  background: #1c57b1;
  line-height: 40px;
  text-align: center;
  margin-top: 20px;
  a {
    color: #fff !important;
    font-weight: bold;
  }
}
