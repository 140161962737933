/* 重点企业 */
.drven-list {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9dee8;
}
.drven-list > li {
  cursor: pointer;
  flex: 1;
  text-align: center;
}
.drven-list .drven-icon {
  margin: auto;
  width: 72px;
  height: 72px;
}
.drven-list > li p {
  margin-top: 4px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1b2b3a;
  line-height: 24px;
}
.drven-list > li .drven-icon-desc {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
  line-height: 24px;
  margin-top: 2px;
}
.drven-list .drven-active-line {
  margin-top: 24px;
  width: 100%;
  height: 2px;
  position: relative;
  top: 1px;
}
.drven-list .active {
  background-image: linear-gradient(90deg, #4a36ff, #5367fd, #84e6ff);
}
.drven-list .drven-icon img {
  width: 100%;
  height: 100%;
}
.about {
  background-color: #f2f3f5;
  padding-bottom: 30px;
}
.about video {
  flex: 0 0 35%;
  height: 200px;
  margin-left: 40px;
}
.desc {
  flex: 0 0 60%;
  line-height: 20px;
  color: #666;
  font-size: 13px;
}
.about h2 {
  padding: 40px 0;
}

.subt {
  color: #496fbb;
  font-weight: normal;
  margin-bottom: 12px;
  font-size: 24px;
}
.subt + p {
  color: #757575;
  margin-bottom: 30px;
}

.videos {
  margin-bottom: 40px;
  margin-top: 40px;
  padding: 0 100px;
}
.videos a {
  display: block;
  width: 384px;
  height: 216px;
  border-radius: 10px;
  background: url(/public/static/img/img/3.jpg) center no-repeat;
  background-size: cover;
}
.videos div:nth-of-type(2) a {
  background-image: url(/public/static/img/img/4.jpg);
}
.videos > div {
  margin: 0 40px;
  width: 384px;
  height: 216px;
  position: relative;
}
.videos > div > a:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 384px;
  height: 216px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
.videos .play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  z-index: 1;
  opacity: 0.7;
}

.aboutcont {
  padding: 40px 100px;
}
.aboutcont > .swiper {
  margin-bottom: 20px;
}
.aboutcont img {
  height: 400px !important;
}
.aboutcont p {
  line-height: 32px;
  font-size: 16px;
  text-indent: 2em;
}

.tabs {
  justify-content: center;
  margin: 60px auto;
}
.tabs li {
  margin: 0 30px;
  padding-bottom: 8px;
  cursor: pointer;
}
.tabs li.active {
  border-bottom: 2px solid #496fbb;
  color: #496fbb;
}

.cardimg {
  padding: 0 50px;
  align-items: center;
  margin-bottom: 50px;
}
.cardimglist .cardimg:nth-of-type(2) > div {
  margin-right: 40px;
  margin-left: 0;
  flex: 0 0 50%;
  width: 440px;
}
.cardimglist2 .cardimg div {
  margin-right: 40px;
  margin-left: 0;
  align-items: center;
  margin-bottom: 50px;
}
.cardimglist2 .cardimg:nth-of-type(even) > div {
  margin-left: 40px;
  margin-right: 0;
  flex: 0 0 50%;
  width: 440px;
}
.cardimg img {
  flex: 0 0 50%;
  width: 440px;
  height: 280px;
  object-fit: fill;
}
.cardimg > div {
  flex: 0 0 50%;
  margin-left: 40px;
  font-size: 13px;
  line-height: 24px;
}

.honoritem img {
  width: 240px;
  height: 380px;
}
.justify-center {
  justify-content: center;
}
.honor {
  padding: 40px 80px;
}
.honoritem {
  margin: 0 20px;
}
.honoritem p {
  background-color: #1b2b3a;
  color: #fff;
  padding: 6px 10px;
  text-align: center;
  margin-top: 20px;
  line-height: 18px;
  font-size: 13px;
}

.pct {
  margin: 0 80px;
  background-color: #1b2b3a;
  color: #fff;
  padding: 0 30px;
  line-height: 32px;
  margin-bottom: 20px;
  text-align: center;
}

.inner-padding-tb2 .item .content {
  padding-top: 85px;
}
.inner-padding-tb2 .item .title span {
  position: absolute;
  top: 52px;
}
.inner-padding-tb2 .item .circle .lingxing {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.inner-padding-tb2 .item.on .circle .lingxing {
  animation: transformScaleTo1 0.3s forwards;
}
.inner-padding-tb2 .item:nth-child(odd) .title span {
  left: 378px;
}
.inner-padding-tb2 .item.on .title {
  font-size: 24px;
  color: #fff;
}
.inner-padding-tb2 .item.on:nth-child(even) .title span {
  left: 523px;
}
.inner-padding-tb2 .item.on:nth-child(odd) .title span {
  left: 518px;
}
.inner-padding-tb2 .item.on .content {
  animation: paddingTop34 0.3s forwards;
}
.inner-padding-tb2 {
  width: 1190px;
  margin: 0 auto 60px;
  padding: 60px 30px;
  position: relative;
}
.inner-padding-tb2 .line {
  height: 96%;
  position: absolute;
  left: 50%;
  width: 10px;
  background: #a9a9a9;
  margin-left: -5px;
}
.inner-padding-tb2 .item:nth-child(even) {
  padding-left: 600px;
  text-align: left;
}
.inner-padding-tb2 .item.on:nth-child(odd) {
  padding-right: 650px;
}
.inner-padding-tb2 .item.on:nth-child(even) {
  padding-left: 650px;
}
.inner-padding-tb2 .item {
  position: relative;
  font-size: 16px;
}
.inner-padding-tb2 .item .circle {
  left: 504px;
  width: 121px;
  height: 131px;
  position: absolute;
}
.inner-padding-tb2 .item .circle .lingxing {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.inner-padding-tb2 .item .circle .donut {
  position: absolute;
  -webkit-transform: scale(1);
  transform: scale(1);
  top: 50%;
  left: 50%;
  margin-left: -13px;
  margin-top: -12px;
}
.inner-padding-tb2 .item .title {
  font-size: 20px;
  color: #2c901e;
}
.inner-padding-tb2 .item.off .content {
  animation: paddingTop85 0.3s forwards;
}
.inner-padding-tb2 .item .content {
  padding-top: 85px;
  line-height: 28px;
}
.inner-padding-tb2 .item:nth-child(odd) {
  padding-right: 600px;
  text-align: right;
}
.inner-padding-tb2 .item.on .circle .donut {
  animation: transformScaleTo0 0.3s forwards;
}
@keyframes transformScaleTo1 {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes transformScaleTo0 {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes paddingTop34 {
  to {
    padding-top: 34px;
  }
}
