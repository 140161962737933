#root {
  width: 100%;
  overflow: hidden;
}
.warp {
  width: 100vw;
  overflow: hidden;
}
.header_img {
  position: relative;
  height: 300px;
}
.header_img > .header_bg {
  position: absolute;
  left: 0;
  top: 0;
}
.header_img > .header_title {
  position: absolute;
  left: 60px;
  height: 80px;
  top: 110px;
}
@media screen and (min-width: 1920px) {
  .circuitBg {
    position: fixed;
    width: 100px;
    height: 100vh;
    background: url(/public/static/img/bg1.png) center / contain no-repeat;
    left: calc(50vw - 940px);
    top: 0;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
  }
  .circuitBg1 {
    left: calc(50vw - 980px);
  }
  .circuitBg2 {
    left: auto;
    right: calc(50vw - 940px);
  }
  .circuitBg3 {
    left: auto;
    right: calc(50vw - 980px);
  }
  .circuit {
    z-index: 1111;
    position: absolute;
    width: 100px;
    height: 160px;
    background: url(/public/static/img/3.gif) center / contain no-repeat;
    left: calc(50vw - 960px);
    top: -200px;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
  }
  .circuit2 {
    background-image: url(/public/static/img/3.gif);
    left: auto;
    right: calc(50vw - 960px);
  }
}

.center {
  margin: 0 auto;
  min-width: 1080px;
  max-width: 1210px;
  padding: 0 10px;
  box-sizing: border-box;
}
.flex {
  display: flex;
}
.flex-sub {
  flex: 1;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.align-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.flex-wrap {
  flex-wrap: wrap;
}
.wow {
  animation-duration: 1s !important;
}
.back {
  position: fixed;
  width: 50px;
  height: 50px;
  top: 1100px;
  right: 20px;
  background:#fff;
  border-radius: 50%;
  z-index: 22;
}
