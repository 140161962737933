.News {
  .tabs {
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    margin: 60px auto 1px;
    font-size: 16px;

    a {
      width: 116px;
      line-height: 45px;
      text-align: center;
    }

    .active {
      color: #fff;
      background: #496fbb;
    }
  }

  .left {
    width: 898px;
    border: 1px solid #e9eff2;

    .list {
      .item {
        cursor: pointer;
        height: 202px;
        padding: 40px 36px;
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px dashed #e9eff2;

        .l {
          width: 282px;
          height: 120px;
        }

        .r {
          width: 100%;
          margin: 0 0 0 20px;

          .title {
            font-size: 18px;
            line-height: 24px;
            margin: 0 0 7px;
            color: #3b4857;
            font-weight: bold;
          }

          .description {
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 20px;
            height: 44px;
            color: #aab5c1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          .b {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #8a929a;

            .b_l {
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }

            .author {
              margin: 0 20px 0 0;
            }

            .time {

              // width: 20px;
              img {
                width: 20px;
              }
            }

            .readCount {
              display: flex;
              align-items: center;

              i {
                display: block;
                width: 16px;
                height: 16px;
                background: url('../../../public/static/img/icon-policy.png') no-repeat;
                background-position: -48px -208px;
                margin-right: 10px;
              }
            }

            .publishTime {
              color: #aab5c1;
            }
          }
        }
      }
    }

    .ant-pagination {
      padding: 0 10px 10px;
    }
  }

  .right {
    width: 268px;
    margin: 0 0 0 20px;

    .sub_title {
      line-height: 52px;
      background-color: #fafbfc;
      padding: 0 0 0 28px;
      font-weight: bold;
      color: #475469;
      border-bottom: 1px solid #e3e9ee;
    }

    //   推荐资讯
    .information {
      border: 1px solid #e3e9ee;
      margin: 0 0 20px;

      .item {
        display: flex;
        padding: 23px 25px;
        border-bottom: 1px dashed #e3e9ee;

        &:last-child {
          border-bottom: none;
        }

        .l {
          width: 72px;
          height: 48px;
          margin: 0 10px 0 0;
        }

        .r {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }

    // 阅读榜单
    .list {
      border: 1px solid #e3e9ee;

      .item {
        display: flex;
        padding: 23px 25px;
        border-bottom: 1px dashed #e3e9ee;
        align-content: flex-start;

        &:last-child {
          border-bottom: none;
        }

        .l {
          width: 14px;
          height: 14px;
          line-height: 14px;
          margin: 0 10px 0 0;
          background-color: #aab5c1;
          flex-shrink: 0;
          font-size: 12px;
          text-align: center;
          border-radius: 4px;
          color: #fff;
        }

        .r {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
}