
footer {
  margin-top: 100px;
  padding: 40px 0;
  /* background-color: #000; */
  /* background-image: url(/public/static/img/bottom.jpeg); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #000;
  border-top: 1px solid #ccc;
  position: relative;
  .footer-top{
    display: flex;
    justify-content: center;
    img{
    }
  }
  .footer{
    width: 100%;
    display: flex;
    justify-content: center;
    padding:50px 0 0 ;
    .l{
      flex-shrink: 0;
      text-align: center;
      img{
        width: 80px;
      }
    }
    .r{
      margin:0 0 0 20px ;

      .list{
        padding:0 0 23px 0 ;
        a{
          color:#496fbb
        }
      }
    }
  }

}
