.enterprise {
  padding: 40px 0 0;
  .item {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0 80px 0;
    .title {
      width: 30%;
      background-color: #1b2b3a;
      color: #fff;
      padding: 8px 0;
      text-align: center;
      font-size: 18px;
    }
    .img {
      width: 100%;
      margin: 40px 0 0 0;
      text-align: center;
      img {
        width: 75%;
      }
    }
    .detail {
      flex-wrap: wrap;
      margin: 40px 0 0 0;
      font-size: 16px;
      text-indent: 32px;
      width: 75%;
    }
  }
}
.videoMask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  video {
    width: 100%;
  }
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    color: #fff;
    cursor: pointer;
    z-index: 11;
  }
}

.platform {
  .marqueeList {
    display: flex;
  }
  .item {
    margin: 0 20px 0 0;
  }
  // .swiper-slide {
  //   padding: 0 10%;
  //   display: flex;
  //   flex-wrap: wrap;
  // }
  // .item {
  //   width: 18%;
  //   margin: 0 0 1% 2%;
  //   a {
  //     display: flex;
  //     flex-wrap: wrap;
  //     box-sizing: border-box;
  //     overflow: hidden;
  //   }
  .swiper {
    height: 150px;
  }
  img {
    width: 100% !important;
    height: 100px;
    object-fit: cover;
  }
  .name {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    text-align: center;
  }
  // }
}
.article {
  padding: 0 4%;
  height: auto;
  overflow: hidden;
  opacity: 0;
  transition: all 2s ease-in-out 3s;
  img {
    width: 100%;
  }
  .title {
    width: 390px;
    line-height: 32px;
    background-color: #1b2b3a;
    color: #fff;
    text-align: center;
    margin: 20px calc((100% - 390px) / 2);
  }
  .desc {
    font-size: 16px;
    line-height: 32px;
    text-indent: 2em;
    // padding: 0 15%;
  }
}
.active {
  height: auto;
  overflow: auto;
  opacity: 1;
}
.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 2s;
}
// .enterprise1 {
//   .swiper {
//     margin: 0 10%;
//   }
//   .swiper-slide {
//     overflow-x: auto;
//     // display: grid;
//     // grid-template-columns: repeat(7, 18%);
//     // grid-column-gap: 2%;
//     // grid-row-gap: 2%;

//     .item {
//       // grid--gap: 2%;
//       // grid-column-gap: 90px;
//       a {
//         display: flex;
//         flex-wrap: wrap;
//         box-sizing: border-box;
//         overflow: hidden;
//       }
//       img {
//         width: 100%;
//         height: 100px;
//         object-fit: cover;
//       }
//       .name {
//         width: 100%;
//         background: rgba(0, 0, 0, 0.5);
//         padding: 5px 10px;
//         color: #fff;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         white-space: nowrap;
//         font-size: 12px;
//       }
//     }
//   }
// }
// .enterprise1 {
//   .list {
//     .item {
//       width: 12.2%;
//       margin: 0 0 2% 2%;
//     }
//   }
// }
