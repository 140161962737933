.logo {
  width: 48px;
}
.header {
  position: relative;
  min-width: 1300px !important;
}
.logoContent{
  width: 300px;
  flex-shrink: 1;
}
.logoName {
  width: 100px;
  font-size: 22px;
  margin-left: 10px;
  color: rgb(6, 73, 158);
  font-weight: bold;
}
.logoName p {
  font-size: 12px;
  margin-bottom: 0;
  line-height: 12px;
}
.nav {
  flex: 1;
  /*margin-left: 50px;*/
  /*margin-right: 50px;*/
}
.nav li {
  width: 130px;
  /*flex: 1;*/
  text-align: center;
  line-height: 50px;
  padding-top: 52px;
  height: 120px;
  cursor: pointer;
}
.nav li:nth-child(5).active {
  background: url('/public/static/img/active.png') center 0 / auto 120px
    no-repeat;
}
.nav > li:nth-child(2).active {
  background: url('/public/static/img/a1.png') center 0 / auto 120px no-repeat;
}
.nav > li:nth-child(3).active {
  background: url('/public/static/img/a2.png') center 0 / auto 120px no-repeat;
}
.nav > li:nth-child(4).active {
  background: url('/public/static/img/a3.png') center 0 / auto 120px no-repeat;
}
.nav > li:nth-child(1).active {
  background: url('/public/static/img/a4.png') center 0 / auto 120px no-repeat;
}
.nav > li:nth-child(6).active {
  background: url('/public/static/img/a1.png') center 0 / auto 120px no-repeat;
}
.nav li:last-child{
  width: 200px !important;

}
.nav li:last-child span{
  width: 200px ;

}
.nav li span {
  padding: 4px 12px;
  font-size: 16px;
  transition-duration: 0.3s;
}
.nav li.active span,
.nav li:hover span {
  border-radius: 5px;
  background-color: #496fbb;
  color: #fff;
}
